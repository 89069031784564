import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { EmrCompletionComponent } from './pages/emr-completion/emr-completion.component';
import { HomeScreenComponent } from './pages/home-screen/home-screen.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent
  },
  {
    path: 'back-to-home',
    component: HomeScreenComponent
  },
  {
    path: 'complete',
    component: EmrCompletionComponent
  },
  {
    path: 'aesthetics',
    loadChildren: () => import('./modules/aesthetic/aesthetic.module').then(m => m.AestheticModule)
  },
  {
    path: 'breast',
    loadChildren: () => import('./modules/breast/breast.module').then(m => m.BreastModule)
  },
  {
    path: 'gynae',
    loadChildren: () => import('./modules/general-gynaecology/general-gynaecology.module').then(m => m.GeneralGynaecologyModule)
  },
  {
    path: 'ent',
    loadChildren: () => import('./modules/ent/ent.module').then(m => m.EntModule)
  },
  {
    path: 'ht',
    loadChildren: () => import('./modules/hair-transplant/hair-transplant.module').then(m => m.HairTransplantModule)
  },
  {
    path: 'procto',
    loadChildren: () => import('./modules/procto/procto.module').then(m => m.ProctoModule)
  },
  {
    path: 'lapro',
    loadChildren: () => import('./modules/lapro/lapro.module').then(m => m.LaproModule)
  },
  {
    path: 'vascular',
    loadChildren: () => import('./modules/vascular/vascular.module').then(m => m.VascularModule)
  },
  {
    path: 'uro',
    loadChildren: () => import('./modules/uro/uro.module').then(m => m.UroModule)
  },
  {
    path: 'kidney-stone',
    loadChildren: () => import('./modules/kidney-stone/kidney-stone.module').then(m => m.KidneyStoneModule)
  },
  {
    path: 'training',
    loadChildren: () => import('./modules/doctor-training/doctor-training.module').then(m => m.DoctorTrainingModule)
  },
  {
    path: 'general-category',
    loadChildren: () => import('./modules/general-category/general.module').then(m => m.GeneralModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
