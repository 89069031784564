import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Observable, Subscriber, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('noInternet') noInternet!: TemplateRef<any>
  private _navigatorObj: any = window.navigator;
  isSlowConnect: boolean = false
  private _sub = new Subscriber()
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog
  ) {
    if (environment.production && !localStorage.getItem('categoryChanged')) {
      localStorage.clear();
    } else if (localStorage.getItem('categoryChanged')) {
      localStorage.removeItem('categoryChanged');
    }
    this._sub.add(
      _route.queryParams.subscribe(val => {
        for (const key in val) {
          if (Object.prototype.hasOwnProperty.call(val, key)) {
            if (val[key] === 'Online Consulting') {
              localStorage.setItem(key, 'Online Consult')
            } else {
              localStorage.setItem(key, val[key])
            }
          }
        }
        if (val.isIrrelevant) {
          switch (val.category) {
            case 'Aesthetics':
              localStorage.setItem('category-name', 'aesthetics');
              _router.navigateByUrl('/general-category');
              break;
            case 'Breast':
              localStorage.setItem('category-name', 'breast');
              _router.navigateByUrl('/general-category');
              break;
            case 'gynae':
              localStorage.setItem('category-name', 'general-gynae');
              _router.navigateByUrl('/general-category');
              break;
            case 'General Gynaecology':
              localStorage.setItem('category-name', 'general-gynae');
              _router.navigateByUrl('/general-category');
              break;
            case 'Gynaecology':
              localStorage.setItem('category-name', 'general-gynae');
              _router.navigateByUrl('/general-category');
              break;
            case 'Gynaecology MTP':
              localStorage.setItem('category-name', 'general-gynae');
              _router.navigateByUrl('/general-category');
              break;
            case 'Surgical Gynaecology':
              localStorage.setItem('category-name', 'general-gynae');
              _router.navigateByUrl('/general-category');
              break;
            case 'ENT':
              localStorage.setItem('category-name', 'ent');
              _router.navigateByUrl('/general-category');
              break;
            case 'HT':
              localStorage.setItem('category-name', 'hair-transplant');
              _router.navigateByUrl('/general-category');
              break;
            case 'Proctology':
              localStorage.setItem('category-name', 'proctology');
              _router.navigateByUrl('/general-category');
              break;
            case 'Pilonidal Sinus':
              localStorage.setItem('category-name', 'proctology');
              _router.navigateByUrl('/general-category');
              break;
            case 'Laparoscopy':
              localStorage.setItem('category-name', 'laparoscopy');
              _router.navigateByUrl('/general-category');
              break;
            case 'Vascular':
              localStorage.setItem('category-name', 'vascular');
              _router.navigateByUrl('/general-category');
              break;
            case 'Urology':
              localStorage.setItem('category-name', 'urology');
              _router.navigateByUrl('/general-category');
              break;
            case 'Urology 2':
              localStorage.setItem('category-name', 'urology');
              _router.navigateByUrl('/general-category');
              break;
            case 'Kidney Stone':
              localStorage.setItem('category-name', 'kidneyStone');
              _router.navigateByUrl('/general-category');
              break;
            default:
              localStorage.setItem('category-name', val.category);
              _router.navigateByUrl('/general-category');
              break;
          }
        } else {
          switch (val.category) {
            case 'Aesthetics':
              localStorage.setItem('category-name', 'aesthetics');
              _router.navigateByUrl('/aesthetics');
              break;
            case 'Breast':
              localStorage.setItem('category-name', 'breast');
              _router.navigateByUrl('/breast');
              break;
            case 'gynae':
              localStorage.setItem('category-name', 'general-gynae')
              _router.navigateByUrl('/gynae')
              break;
            case 'General Gynaecology':
              localStorage.setItem('category-name', 'general-gynae')
              _router.navigateByUrl('/gynae')
              break;
            case 'Gynaecology':
              localStorage.setItem('category-name', 'general-gynae')
              _router.navigateByUrl('/gynae')
              break;
            case 'Gynaecology MTP':
              localStorage.setItem('category-name', 'general-gynae')
              _router.navigateByUrl('/gynae')
              break;
            case 'Surgical Gynaecology':
              localStorage.setItem('category-name', 'general-gynae')
              _router.navigateByUrl('/gynae')
              break;
            case 'ENT':
              localStorage.setItem('category-name', 'ent')
              _router.navigateByUrl('/ent')
              break;
            case 'HT':
              localStorage.setItem('category-name', 'hair-transplant')
              _router.navigateByUrl('/ht')
              break;
            case 'Proctology':
              localStorage.setItem('category-name', 'proctology')
              _router.navigateByUrl('/procto')
              break;
            case 'Pilonidal Sinus':
              localStorage.setItem('category-name', 'proctology')
              _router.navigateByUrl('/procto')
              break;
            case 'Laparoscopy':
              localStorage.setItem('category-name', 'laparoscopy')
              _router.navigateByUrl('/lapro')
              break;
            case 'Vascular':
              localStorage.setItem('category-name', 'vascular')
              _router.navigateByUrl('/vascular')
              break;
            case 'Urology':
              localStorage.setItem('category-name', 'urology')
              _router.navigateByUrl('/uro')
              break;
            case 'Urology 2':
              localStorage.setItem('category-name', 'urology')
              _router.navigateByUrl('/uro')
              break;
            case 'Kidney Stone':
              localStorage.setItem('category-name', 'kidneyStone')
              _router.navigateByUrl('/kidney-stone')
              break;
          }
        }


        navigator.geolocation.getCurrentPosition((position: any) => {
          localStorage.setItem('latitude', position.coords.latitude)
          localStorage.setItem('longitude', position.coords.longitude)
        }, (error: any) => {
          console.error(error.message)
        }, { timeout: 3000 })
      })
    )
  }
  ngOnInit(): void {
    if (this._navigatorObj && this._navigatorObj.connection) {
      if (this._navigatorObj.connection && this._navigatorObj.connection.effectiveType && /\slow-2g|2g/.test(this._navigatorObj.connection.effectiveType)) {
        this.isSlowConnect = true;
      }
      this._navigatorObj.connection.addEventListener('change', (event: any) => {
        if (/\slow-2g|2g/.test(this._navigatorObj.connection.effectiveType)) {
          this.isSlowConnect = true;
        } else {
          this.isSlowConnect = false;
        }
      });
    }

    this._sub.add(
      fromEvent(window, 'online').subscribe(val => {
        this._dialog.getDialogById('connection-dialog')?.close()

      })
    )
    this._sub.add(
      fromEvent(window, 'offline').subscribe(val => {
        if (!this._dialog.getDialogById('connection-dialog')) {
          this._dialog.open(this.noInternet, {
            disableClose: true,
            id: 'connection-dialog'
          })
        }

      })
    )
  }
  title = 'emr-web';

  ngOnDestroy(): void {
    if (this._navigatorObj && this._navigatorObj.connection) {
      this._navigatorObj.connection.removeAllListeners('change')
    }
    this._sub.unsubscribe()
  }
}
